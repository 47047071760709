body {
  overflow-x: hidden !important;
  font-family: 'Montserrat';
}

* {
  padding: 0px;
  margin: 0px;
}

p {
  font-size: 15px;
  font-weight: 450;
  line-height: 25px;
}

.txt-center {
  text-align: center;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

/* Banner Start */

.ant-carousel .slick-slide img {
  width: 100%;
}

.slick-dots li button {
  background-color: #FEBD2D !important;
}

.slick-dots .slick-active button {
  background-color: #000 !important;

}

.address p a {
  color: #000;
}

/* Banner End */


/* Zig zag Start */

.zig-zag {
  padding-top: 70px;
  padding-bottom: 70px;
}

.zig-zag p {
  text-align: justify;
}

.zig-zag h2 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 60px;
  margin-bottom: 20px;
}

.yellow_hr {
  background-color: #FEBD2D;
  width: 34px;
  height: 8px;
  position: relative;
  top: -34px;
  z-index: -99;
  border: none;
}

.yellows_hrs {
  width: 35px;
  height: 8px;
  left: 41.5%;
  margin-top: -30px;
  background-color: #FEBD2D;
  position: relative;
  z-index: 1 !important;
}

.yelloww_hr {
  background-color: #FEBD2D;
  width: 45px;
  height: 8px;
  position: relative;
  top: -30px;
  left: 29%;
  z-index: 1;
  border: none;
}

/* Zig zag End */

/* How we work Start */

.how-we {
  background-color: #F9F9F9;
  padding: 50px;
  padding-top: 70px;
  padding-bottom: 70px;
}

.how-we-part {
  padding-top: 60px;
  padding-bottom: 60px;
}

.how-we h1 {
  text-align: center;
  font-weight: 700;
  font-size: 25px;
  z-index: 99 !important;
  position: relative;
}

.how-we p {
  margin-top: 30px;
  font-weight: 400;
  color: #AAAAAA;
}

.first-part {
  display: flex;
  justify-content: space-evenly;
  min-height: 300px;
}

.first-part img {
  position: relative;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.10), 0 10px 10px rgba(0, 0, 0, 0.22);
  width: 100%;
}

.first-part .inside-img img {
  margin-top: 50px;
  box-shadow: none;
  width: 80%;
}

.second-part {
  display: flex;
  justify-content: space-evenly;
  min-height: 300px;
}

.second-part img {
  position: relative;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.10), 0 10px 10px rgba(0, 0, 0, 0.22);
  width: 100%;
}

.second-part .inside-img img {
  box-shadow: none;
  margin-top: 100px;
  width: 90%;
  padding-bottom: 15px;
}

.third-part {
  display: flex;
  justify-content: space-evenly;
  min-height: 300px;
}

.third-part img {
  position: relative;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.10), 0 10px 10px rgba(0, 0, 0, 0.22);
  width: 100%;
}

.third-part .inside-img img {
  box-shadow: none;
  margin-top: 80px;
  width: 50%;
}

.fourth-part {
  display: flex;
  justify-content: space-evenly;
  min-height: 300px;
}

.fourth-part img {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.10), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  border-radius: 10px;
  width: 100%;
}

.fourth-part .inside-img img {
  box-shadow: none;
  margin-top: 100px;
  width: 100%;
}

.inside-img {
  position: absolute;
}

.inside-img h2 {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  position: relative !important;
  margin-top: 10px;
}

.inside-img img {
  width: 200px;
}

.yellows_hr {
  width: 35px;
  height: 8px;
  left: 38%;
  margin-top: -30px;
  background-color: #FEBD2D;
  position: relative;
  z-index: 1 !important;
}

.ar-g {
  animation-name: logo;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.img {
  animation-name: logo;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.imgs {
  animation-name: logo;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes logo {
  from {
    transform: translate(0, 0px);
  }

  65% {
    transform: translate(0, 15px);
  }

  to {
    transform: translate(0, -0px);
  }
}

.works {
  background-color: #fff;
  padding-bottom: 0px !important;
}

.works h2 {
  font-size: 17px;
  font-weight: 600;
  margin-top: 50px;
}

.works h2 span {
  font-size: 22px;
  color: #FEBD2D;
  font-weight: 700;
}

.works h6 {
  font-size: 15px;
  font-weight: 400;
  color: #AAAAAA;
}



/* How we work End */


/* Benefits Area Start */

.benefits {
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
}

.benefits-area {
  background-color: #F8F8F8;
  border-radius: 0px 60px 0px 60px;
  margin-top: 50px;
  position: relative;
  padding: 50px 40px;
  color: #FFFFFF;
  margin-top: 70px;
}

.benefits-area h1 {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 60px;
  text-align: left;
  line-height: 60px;
  margin: 15px 0 10px 0;
  font-weight: 500;
}

.benefits-area p {
  margin: 0 0 15px 0;
  letter-spacing: 1.6px;
  font-weight: 300;
}

.benefits-area p span {
  font-size: 0.75rem;
  text-decoration: line-through;
  color: #ff8379;
}

.benefits-area span.border_btm:after {
  display: block;
  content: "";
  width: 130px;
  height: 130px;
  position: absolute;
  bottom: -15px;
  right: -15px;
  border-bottom: 4px solid #FEBD2D;
  border-right: 4px solid #FEBD2D;
}

.benefits-area:before {
  display: block;
  content: "";
  width: 130px;
  height: 130px;
  position: absolute;
  top: -15px;
  left: -15px;
  border-top: 4px solid #FEBD2D;
  border-left: 4px solid #FEBD2D;
}

.round-img {
  margin: 20px;
  width: 150px;
  height: 150px;
  background-color: #f9ebcd;
  border-radius: 50%;
  margin-left: 50px;
}

.round-img img {
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.round h4 {
  font-weight: 600;
  font-size: 17px;
}

.round p {
  color: #9e9d9d;
  font-size: 13.5px;
  letter-spacing: normal;
  font-weight: 400;
}

.benefits h1 {
  font-size: 25px;
  font-weight: 700;
  position: relative;
  z-index: 99;
}

/* Benefits Area End */

/* Popup page Start */

.ant-modal-header,
.ant-modal-footer {
  display: none;
}

.popup .part {
  display: flex;
  justify-content: space-evenly;
  align-content: space-between;
  flex-wrap: wrap;
  background-color: #FEBD2D;
  padding: 30px;
  padding-top: 0px;
  line-height: 60px;
  cursor: pointer;
}

.popup .parts {
  display: flex;
  justify-content: space-evenly;
  align-content: space-between;
  flex-wrap: wrap;
  padding: 30px;
  padding-top: 0px;
  line-height: 60px;
  cursor: pointer;
}

.part h1,
.parts h1 {
  font-size: 25px;
  top: 30px;
  font-weight: 600;
  position: relative;
}

.download .ant-modal-body {
  padding: 0px !important;
}

.close .anticon-close {
  background-color: #fff;
  position: absolute;
  right: -10px;
  padding: 8px;
  top: -10px;
  font-size: 13px;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.links img {
  margin-top: -30px;
}

.mobile-head .anticon-close {
  position: none;
}

.mobile-head Button {
  border-radius: 2px !important;
  padding: 20px !important;
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.mobile-head Button:hover {
  background-color: #fff;
}

.ant-drawer-body ul {
  list-style: none !important;
  color: #000;
  text-align: center;
}

.ant-drawer-body ul li a {
  color: #000 !important;
  font-size: 20px;
  line-height: 55px !important;
  margin-left: 10px;
}

.ant-drawer-body Button {
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border-radius: 50px;
}

.ant-drawer-title {
  display: none;
}

/* Popup page End */


/* Why zuno start  */
.why-zuno {
  padding: 40px;
  padding-top: 0px !important;
  padding-bottom: 0px;
}

.size {
  color: red;
}

.popup h1 {
  font-size: 18px;
  padding-bottom: 10px;
  margin-top: 20px;
}

.popup {
  padding: 40px;
  margin-top: 100px;
}

.popup .txt-center {
  width: 100%;
}

.popup img {
  padding-top: 20px;
  padding-bottom: 20pxs;
}

.popup h3 {
  margin-top: 20px;
}

/* Why zuno end */

/* Mobile Alignment Start */

@media only screen and (max-width: 600px) {

  /* Banner Start */
  .ant-carousel .slick-dots-bottom {
    bottom: -40px;
  }

  .banner img {
    position: relative;
    top: 0px;
    padding-left: 23px;
  }

  .banner h1 {
    font-size: 30px;
    margin-top: 0px;
    font-weight: 550;
  }

  /* Banner End */

  /* Zig Zag Start */

  .zig-zag h2 {
    margin-top: 20px !important;
  }

  .zig-zag {
    margin: 0px;
    padding: 5px 5px !important;
  }

  .zig-zag img {
    margin-top: 10px;
  }

  /* Zig Zag End */

  /* Roller Start */

  .Apps {
    display: none !important;
  }

  /* Roller End */

  /* How-we Start */

  .how-we {
    padding: 10px !important;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .how-we-part {
    margin: 0px !important;
  }

  .how-we p {
    text-align: justify !important;
    padding: 5px 5px !important;
  }

  .inside-img h2 {
    font-size: 12px !important;
  }

  .second-part .inside-img img {
    margin-top: 90px !important;
  }

  .first-part {
    min-height: auto;
  }

  .first-part h2 {
    margin-top: 0px;
  }

  .second-part {
    min-height: auto;
  }

  .second-part h2 {
    margin-top: -20px;
  }

  .third-part {
    min-height: auto;
  }

  .third-part .inside-img img {
    margin-top: 40px;
  }

  .fourth-part {
    min-height: auto;
  }

  .fourth-part h2 {
    margin-top: 25px;
  }

  .fourth-part .inside-img img {
    margin-top: 50px;
  }

  .works .txt-center {
    text-align: justify !important;
    padding: 5px 5px !important;

  }

  .works h2 {
    margin-top: 20px !important;
  }

  br {
    display: none !important;
  }

  /* How-we End */

  /* Why zuno Start */

  .why-zuno {
    padding: 5px 5px !important;
  }

  .why-zuno .ant-col img {
    margin-bottom: 40px;
  }

  .why-zuno h2 {
    text-align: center !important;
  }

  .why-zuno h6 {
    text-align: justify !important;
  }

  /* Why zuno Start */

  /* Benefits Start  */
  .benefits p {
    text-align: justify !important;
  }

  .benefits .head-paragraph {
    margin-top: 20px;
  }

  .phone {
    width: 50%;
  }

  .mobile {
    display: none;
  }

  .footer {
    margin: 0px !important;
  }

  .mini-footer {
    margin: 0px !important;
  }

  .new-one li {
    font-weight: 550;
    text-align: justify;
    margin-left: 20px !important;
  }

  /* Benefits End  */

}

/* Mobile Alignment End */

Button {
  white-space: nowrap;
}

.ant-drawer-content-wrapper {
  height: 100vh !important;
}

.address h4 {
  font-size: 16px;
  margin-left: 20px;
}

.address p {
  margin-bottom: 3px !important;
}

.bo {
  font-size: 17px;
}