/* Navbar Start */

.navbar-head {
    background-color: transparent;
    padding: 20px;
    min-height: 100px;
    width: 100%;
}

.navbar-head ul li {
    margin: 15px 15px 0px 15px;
    white-space: nowrap;
    z-index: 99;
}

.navbar-head ul li a {
    color: #000;
    font-weight: 500;
    font-size: 14px;
    z-index: 99;

}

.navbar-head ul {
    display: flex;
    align-items: baseline;
    list-style: none;
    flex-wrap: wrap;
    align-content: stretch;
    /* justify-content: space-around; */
    justify-content: flex-end;
    z-index: 99;
}

.navbar-head button {
    background-color: #000;
    color: #fff;
    border-radius: 50px;
    padding: 5px 20px;
    font-size: 12px;
    cursor: pointer;
}

.navbar-head button:hover {
    opacity: 0.8;
    transition: 0.3s;
}

.navbar-head .logo {
    width: 120px;
    cursor: pointer;
    margin-top: 10px;
}

.navbar-head .active {
    border-bottom: 4px solid #000;
    padding-bottom: 5px;
}

.navbar-head li a:hover {
    border-bottom: 4px solid #000;
    padding-bottom: 5px;
    text-transform: 2s;
    transition: 0.2s;
}



.mob-logo {
    width: 100px;
    position: absolute;
    top: 20px;
}

.mob-nav li {
    font-weight: 600;
}

.ant-drawer-header {
    border: none !important;
}

.mob-nav li .active {
    border-bottom: 4px solid #FEBD2D;
}


.ant-drawer-header,
.ant-drawer-header-title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

li select {
    border: none;
    font-weight: 500;
    cursor: pointer;
}

select:focus {
    outline: none;
}

/* Navbar End */





/* Footer Start */

.footer {
    background-color: #121212;
    padding-left: 30px;
    padding: 70px;
}

.footer h1 {
    color: #fff;
    font-size: 15px;
}

.footer ul li,
.footer ul li a {
    color: #fff;
    list-style: none;
    line-height: 35px;
    opacity: 0.8;
}

.footer .play-store {
    width: 100%;
    margin-top: 10px;
    cursor: pointer;
}

.footer .play-stores {
    width: 100%;
    margin-top: 20px;
    cursor: pointer;
}

.mini-footer {
    background-color: #000;
}

.mini-footer p {
    padding: 5px;
    color: #fff;
    font-size: 12px;
    opacity: 0.6;
    line-height: 15px;
    margin-top: 10px;
}

.mini-footer p a {
    color: #fff !important;
    font-weight: 550;
    letter-spacing: 0.5px;
}

.footer .anticon {
    font-size: 23px;
    color: #fff;
    margin-right: 8px;
    cursor: pointer;
}

.footer h6 {
    color: #fff;
    font-size: 13px;
    margin-top: 20px;
}

.footer .qr {
    width: 100%;
    margin-top: 30px;
}

.scroll button {
    background-color: #FEBD2D;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.scroll button .anticon {
    color: #fff;
    margin-left: 8px;
    margin-top: 5px;
}

/* Footer End */





@media only screen and (max-width: 600px) {

    /* Navbar Start */

    .navbar-head {
        padding: 0px !important;
        padding-left: 20px !important;
        min-height: auto !important;
        box-shadow: 0px 0px 3px 0px #00000029;
        padding-bottom: 10px !important;
        width: 100%;
    }


    .navbar-head .logo {
        width: 90px;
        margin-top: 20px;
    }

    .navbar-head .txt-center {
        text-align: left !important;
    }

    .mob-download Button {
        margin-top: 25px;
        font-size: 14px !important;
        background-color: transparent;
        border: none;
        color: #000;
        font-weight: 600;
        padding: 0px;
    }

    .mob-nav li select {
        font-size: 16px;
        font-weight: 550;
        margin-top: 5px;
    }

    /* Footer Start */
    .footer {
        padding: 40px;
    }

    .footer .play-stores {
        margin-left: 10px;
        margin-top: 10px !important;
    }

    .zig-zag {
        margin: 0px !important;
    }

    /* Footer End */

    /* Navbar End */

}