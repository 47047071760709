.demo-text .text {
    font-size: clamp(5rem, 15vw, 6rem);
    line-height: 1;
    font-weight: 900;
}

.Apps {
    padding-left: 300px;
    padding-top: 150px;
    padding-bottom: 150px;
    background-image: url(./assets/build.webp);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-attachment: fixed;
    background-size: cover;
}

.text {
    text-transform: uppercase;
    line-height: 120px !important;
}

.text img {
    position: relative;
    top: -40px;
    left: 50px;
}